
import { mapActions, mapGetters } from "vuex";
// import {VueAvatar} from 'vue-avatar-editor-improved';
import AddDialog from "@/modules/services/components/add-dialog.vue";
// import { i18n } from '@/i18n';
import i18n from "@/vueI18n";
import firebase from "firebase/app";
import "firebase/firestore";
import { UserModel } from "@/modules/auth/user-model";
const { fields } = UserModel;
import SuccessDialog from "@/modules/services/components/success-dialog.vue";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  data() {
    return {
      rotation: 0,
      scale: 1,
      borderRadius: 0,

      type: false,
      listedForDialog: false,
      cityList: false,
      regionList: false,
      status: false,
      types: [],
      listedFor: [],
      // cities:[],
      // regions:[],
      cityRegions: [],
      images: {},
      statuses: [
        {
          name: {
            en: "Opened",
            ar: "متاح",
          },
        },
        {
          name: {
            en: "Closed",
            ar: "مغلق",
          },
        },
      ],
      // v-models
      name: "",
      spaceArea: "",
      typeModel: {},
      listedForModel: {},
      deactivationModel: {},
      cityModel: {},
      regionModel: {},
      statusModel: {},
      expectedPrice: null,
      averageIncome: null,
      address: "",
      phoneNumber: "",
      active: true,
      deactivate: false,
      description: "",
      uploadIcon: "img:/images/upload_image_facility.png",
      typeName: "",
      listedForName: "",
      deactivationName: "",
      cityName: "",
      regionName: "",
      statusName: "",

      editUrlFirst: "",
      publishDialog: false,

      disableRegion: true,
      createdBy: null,
      createdAt: null,
      imagesUpdate: [],

      validName: false,
      validSpaceArea: false,
      validType: false,
      validListedFor: false,
      validCityName: false,
      validStatus: false,
      validExpectedPrice: false,
      validAverageIncome: false,
      validPhone: false,
      validAddress: false,
      validDescription: false,
      recordId: null,

      loadingUpdate: false,
      //#region [images]
      rawFile: "",
      fileName: "",
      path: "",
      downloadURL: "",
      file: "",
      fileExist: false,
      progress: null,
      progressBar: null,

      rawFiles: [],
      storedImages: 0,
      uploadImagesLoading: false,
      payload: {},
      rawFileOne: null,
      rawFileTwo: null,
      rawFileThree: null,
      rawFileFour: null,
      rawFileFive: null,

      imageOneUrl: null,
      imageTwoUrl: null,
      imageThreeUrl: null,
      imageFourUrl: null,
      imageFiveUrl: null,

      imageOneName: null,
      imageTwoName: null,
      imageThreeName: null,
      imageFourName: null,
      imageFiveName: null,

      deleteImageOneFromStorage: false,
      deleteImageTwoFromStorage: false,
      deleteImageThreeFromStorage: false,
      deleteImageOneFourStorage: false,
      deleteImageOneFiveStorage: false,

      deletedImages: [],

      //#endregion
      countryCode: "+20",
      countryName: "EG",
      isValid: false,
      errorMessage: "",
      phoneLenght: 8,
      phoneMask: "#########",
      TRansObject: {
        countrySelectorLabel: "Code Country",
        countrySelectorError: "Choose a country",
        phoneNumberLabel: "Phone Number",
        example: "Example :",
        countryCallingCode: "+20",
      },
      TRansObjectAr: {
        countrySelectorLabel: "كود البلد",
        countrySelectorError: "اختر دولة",
        phoneNumberLabel: "رقم الجوال",
        example: "مثال :",
      },

      addedPropertyInMonth: 0,
      propertyMaxCount: undefined,
      successMsgTitle: "",
      successMsgDesc: "",

      edit: false,
    };
  },
  computed: {
    lang() {
      // debugger
      return i18n.locale;
    },
    enableSubmit() {
      // && this.validPhoneNumber
      if (
        this.validName &&
        this.validSpaceArea &&
        this.validType &&
        this.validListedFor &&
        this.validCityName &&
        this.validStatus &&
        this.validExpectedPrice &&
        this.validAddress &&
        this.validDescription &&
        this.validPhone
      ) {
        return true;
      }
      return false;
    },
    fields() {
      return fields;
    },
    isRTL() {
      return i18n.locale == "ar";
    },
    ...mapGetters({
      saveLoading: "facilities/form/saveLoading",
      // fetch lists for add
      facilityListedForList: "facilities/list/facilityListedFor",
      facilityTypes: "facilities/list/facilityTypes",
      cities: "facilities/list/cities",
      regions: "facilities/list/regions",
      //  for edit
      recordCreated: "facilities/form/recordCreated",
      currentUser: "auth/currentUser",
      record: "facilities/view/record",
      city: "facilities/view/city",
      region: "facilities/view/region",
      facilityType: "facilities/view/facilityType",
      facilityListedFor: "facilities/view/facilityListedFor",
      deactivationReasons: "facilities/list/deactivationReasons",

      mountedCityTable: "facilities/list/mountedCityTable",
      mountedFacilityTypeTable: "facilities/list/mountedFacilityTypeTable",
      mountedFacilityListedfForTable:
        "facilities/list/mountedFacilityListedfForTable",
      mountedDeactivationReasonTable:
        "facilities/list/mountedDeactivationReasonTable",
      recordMounted: "facilities/view/recordMounted",
      userListener: "auth/userListener",
    }),
  },
  watch: {
    lang() {
      document.title =
        this.i18n("app.title") +
        " | " +
        this.i18n("drugDeal.services.facilities");
    },
    async record(newvalue) {
      if (newvalue) {
        // await this.doFindCity({cityId:newvalue.cityId,regionId:newvalue.regionId})
        // await this.doFindFacilityListedFor({id:newvalue.listedForId})
        // await this.doFindFacilityType({id:newvalue.typeId})
        this.name = newvalue.name;
        this.spaceArea = newvalue.spaceArea;
        this.typeModel["id"] = newvalue.typeId;
        this.listedForModel["id"] = newvalue.listedForId;
        this.deactivationModel["id"] = newvalue.deactivationReasonId;
        this.cityModel["id"] = newvalue.cityId;
        if (newvalue.regionId) {
          this.regionModel["id"] = newvalue.regionId;
        }
        this.createdAt = newvalue.createdAt;
        this.createdBy = newvalue.createdBy;
        this.viewers = newvalue.viewers;
        this.cityRegions = this.regions[newvalue.cityId];
        if (newvalue.isClosed) {
          if (this.isRTL) {
            this.statusName = "مغلق";
          } else {
            this.statusName = "Closed";
          }
        } else {
          if (this.isRTL) {
            this.statusName = "مفتوح";
          } else {
            this.statusName = "Opened";
          }
        }
        this.expectedPrice = newvalue.price;
        this.averageIncome = newvalue.income;
        this.phoneNumber = newvalue.phoneNumber;
        this.validPhone = true;
        this.countryCode = newvalue.countryCode ? newvalue.countryCode : "+20";
        this.countryName = newvalue.countryName ? newvalue.countryName : "EG";
        this.active = newvalue.isActive;
        this.address = newvalue.address;
        this.description = newvalue.description;
        if (newvalue.images) {
          this.images = newvalue.images;
          if (Object.values(newvalue.images).length > 0) {
            this.imageOneUrl = Object.values(newvalue.images)[0];
            this.imageOneName = Object.keys(newvalue.images)[0];
            this.deleteImageOneFromStorage = true;
          }
          if (Object.values(newvalue.images).length > 1) {
            this.imageTwoUrl = Object.values(newvalue.images)[1];
            this.imageTwoName = Object.keys(newvalue.images)[1];
            this.deleteImageTwoFromStorage = true;
          }
          if (Object.values(newvalue.images).length > 2) {
            this.imageThreeUrl = Object.values(newvalue.images)[2];
            this.imageThreeName = Object.keys(newvalue.images)[2];
            this.deleteImageThreeFromStorage = true;
          }
          if (Object.values(newvalue.images).length > 3) {
            this.imageFourUrl = Object.values(newvalue.images)[3];
            this.imageFourName = Object.keys(newvalue.images)[3];
            this.deleteImageFourFromStorage = true;
          }
          if (Object.values(newvalue.images).length > 4) {
            this.imageFiveUrl = Object.values(newvalue.images)[4];
            this.imageFiveName = Object.keys(newvalue.images)[4];
            this.deleteImageFiveFromStorage = true;
          }
        }

        // this.images = newvalue.images
        // if(Object.values(newvalue.images).length > 0){

        // }
      }
    },
    isRTL(newvalue) {
      if (newvalue) {
        if (Object.keys(this.cityModel).length != 0) {
          this.cityName = this.cityModel["name"]["ar"];
        }
        if (Object.keys(this.regionModel).length != 0) {
          this.regionName = this.regionModel["name"]["ar"];
        }
        if (Object.keys(this.typeModel).length != 0) {
          this.typeName = this.typeModel["name"]["ar"];
        }
        if (Object.keys(this.listedForModel).length != 0) {
          this.listedForName = this.listedForModel["name"]["ar"];
        }
        if (Object.keys(this.deactivationModel).length != 0) {
          this.deactivationName = this.deactivationModel["name"]["ar"];
        }
        if (Object.keys(this.statusModel).length != 0) {
          this.statusName = this.statusModel["name"]["ar"];
        }
        if (this.record && this.record.isClosed) {
          this.statusName = "مغلق";
        }
        if (this.record && !this.record.isClosed) {
          this.statusName = "مفتوح";
        }
      } else {
        if (Object.keys(this.cityModel).length != 0) {
          this.cityName = this.cityModel["name"]["en"];
        }
        if (Object.keys(this.regionModel).length != 0) {
          this.regionName = this.regionModel["name"]["en"];
        }
        if (Object.keys(this.typeModel).length != 0) {
          this.typeName = this.typeModel["name"]["en"];
        }
        if (Object.keys(this.listedForModel).length != 0) {
          this.listedForName = this.listedForModel["name"]["en"];
        }
        if (Object.keys(this.deactivationModel).length != 0) {
          this.deactivationName = this.deactivationModel["name"]["en"];
        }
        if (Object.keys(this.statusModel).length != 0) {
          this.statusName = this.statusModel["name"]["en"];
        }
        if (this.record && this.record.isClosed) {
          this.statusName = "Closed";
        }
        if (this.record && !this.record.isClosed) {
          this.statusName = "Opened";
        }
      }
    },
    city(newvalue) {
      if (newvalue) {
        this.cityModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.cityName = newvalue.name.ar;
        } else {
          this.cityName = newvalue.name.en;
        }
      }
    },
    region(newvalue) {
      if (newvalue) {
        this.regionModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.regionName = newvalue.name.ar;
        } else {
          this.regionName = newvalue.name.en;
        }
      }
    },
    facilityType(newvalue) {
      if (newvalue) {
        this.typeModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.typeName = newvalue.name.ar;
        } else {
          this.typeName = newvalue.name.en;
        }
      }
    },
    facilityListedFor(newvalue) {
      if (newvalue) {
        this.listedForModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.listedForName = newvalue.name.ar;
        } else {
          this.listedForName = newvalue.name.en;
        }
      }
    },
    deactivationReason(newvalue) {
      if (newvalue) {
        this.deactivationModel["name"] = newvalue.name;
        if (this.isRTL) {
          this.deactivationName = newvalue.name.ar;
        } else {
          this.deactivationName = newvalue.name.en;
        }
      }
    },
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },

    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      doCreate: "facilities/form/doCreate",
      doUpdate: "facilities/form/doUpdate",
      doFind: "facilities/view/doFind",

      setRecordMounted: "facilities/view/setRecordMounted",
      doFindCity: "facilities/view/doFindCity",
      doFindFacilityListedFor: "facilities/view/doFindFacilityListedFor",
      doFindFacilityType: "facilities/view/doFindFacilityType",

      // fetch lists for add
      doFetchCity: "facilities/list/doFetchCity",
      doFetchFacilityType: "facilities/list/doFetchFacilityType",
      doFetchFacilityListedFor: "facilities/list/doFetchFacilityListedFor",
      doFetchDeactivationReason: "facilities/list/doFetchDeactivationReason",
      setMountedDeactivationReasonTable:
        "facilities/list/setMountedDeactivationReasonTable",
      setMountedCityTable: "facilities/list/setMountedCityTable",
      setMountedFacilityTypeTable:
        "facilities/list/setMountedFacilityTypeTable",
      setMountedFacilityListedForTable:
        "facilities/list/setMountedFacilityListedForTable",
    }),

    validateName(val) {
      if (val.length > 0) {
        this.validName = true;
        return true;
      } else {
        this.validName = false;
        return false;
      }
    },
    validateSpaceArea(val) {
      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.validSpaceArea = true;
        return true;
      } else {
        this.validSpaceArea = false;
        return false;
      }
    },
    validateType(val) {
      if (val.length > 0) {
        this.validType = true;
        return true;
      } else {
        this.validType = false;
        return false;
      }
    },
    validateListedFor(val) {
      if (val.length > 0) {
        this.validListedFor = true;
        return true;
      } else {
        this.validListedFor = false;
        return false;
      }
    },
    validateCityName(val) {
      if (val.length > 0) {
        this.validCityName = true;
        return true;
      } else {
        this.validCityName = false;
        return false;
      }
    },
    validateStatus(val) {
      if (val.length > 0) {
        this.validStatus = true;
        return true;
      } else {
        this.validStatus = false;
        return false;
      }
    },
    validateExpectedPrice(val) {
      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.validExpectedPrice = true;
        return true;
      } else {
        this.validExpectedPrice = false;
        return false;
      }
    },
    validateAverageIncome(val) {
      let isnum = /^\d+$/.test(val);
      if (isnum) {
        this.validAverageIncome = true;
        return true;
      } else {
        this.validAverageIncome = false;
        return false;
      }
    },
    // validatePhone(val){
    //     const phoneno = /^\d{11}$/.test(val)
    //     if (phoneno){
    //         this.validPhoneNumber = true
    //         return true;
    //     }
    //     else {
    //         this.validPhoneNumber = false
    //         return false;
    //     }
    // },

    onPhoneUpdate(data) {
      this.countryCode = "+" + data.countryCallingCode;
      this.countryName = data.countryCode;
      this.isValid = data.isValid;
      this.errorMessage = "";
      this.validPhone = data.isValid;
      // this.validatePhone(data.formattedNumber)
    },

    validateAddress(val) {
      if (val.length > 0) {
        this.validAddress = true;
        return true;
      } else {
        this.validAddress = false;
        return false;
      }
    },
    validateDescription(val) {
      if (val.length > 0) {
        this.validDescription = true;
        return true;
      } else {
        this.validDescription = false;
        return false;
      }
    },
    close() {
      this.$emit("close");
    },
    saveClicked: function saveClicked() {
      var img = this.$refs.vueavatar.getImageScaled();
      this.$refs.image.src = img.toDataURL();
    },
    onImageReady: function onImageReady() {
      this.scale = 1;
      this.rotation = 0;
    },
    closeType(option) {
      this.typeModel = option;
      // this.typeName = this.typeModel['name']
      if (this.isRTL) {
        this.typeName = this.typeModel["name"]["ar"];
      } else {
        this.typeName = this.typeModel["name"]["en"];
      }
      this.type = false;
    },
    closeListedFor(option) {
      this.listedForModel = option;
      if (this.isRTL) {
        this.listedForName = this.listedForModel["name"]["ar"];
      } else {
        this.listedForName = this.listedForModel["name"]["en"];
      }
      this.listedForDialog = false;
    },
    async closeCity(option) {
      this.cityRegions = [];
      this.regionName = "";
      this.cityModel = option;
      if (this.isRTL) {
        this.cityName = this.cityModel["name"]["ar"];
      } else {
        this.cityName = this.cityModel["name"]["en"];
      }
      this.cityList = false;
      if (this.cityModel) {
        this.cityRegions = this.regions[option.id.toString()];
      }
    },
    closeRegion(option) {
      this.regionModel = option;
      if (this.isRTL) {
        this.regionName = this.regionModel["name"]["ar"];
      } else {
        this.regionName = this.regionModel["name"]["en"];
      }
      this.regionList = false;
    },
    toggleIsActive() {
      if (!this.active) {
        this.deactivate = !this.deactivate;
      } else {
        this.deactivationModel = {};
      }

      if (this.active && this.edit) {
        firebase
          .firestore()
          .collection("property")
          .doc(this.$route.params.id)
          .update({
            isActive: true,
            deactivationReasonId: null,
          });
      }
    },
    closeDeactivationReason(option) {
      this.deactivationModel = option;
      if (this.isRTL) {
        this.deactivationName = this.deactivationModel["name"]["ar"];
      } else {
        this.deactivationName = this.deactivationModel["name"]["en"];
      }
      this.deactivate = !this.deactivate;

      if (!this.active && this.edit) {
        firebase
          .firestore()
          .collection("property")
          .doc(this.$route.params.id)
          .update({
            isActive: false,
            deactivationReasonId: this.deactivationModel.id,
          });
      }
    },
    closeStatus(option) {
      this.statusModel = option;
      // this.statusName = this.statusModel['name']['en']
      if (this.isRTL) {
        this.statusName = this.statusModel["name"]["ar"];
      } else {
        this.statusName = this.statusModel["name"]["en"];
      }
      this.status = false;
    },
    generateId() {
      return firebase.firestore().collection(`ids`).doc().id;
    },
    async addMoreFacilities(userListener) {
      this.addedPropertyInMonth = 0;
      var today = new Date();
      if (userListener.property || userListener.property != undefined) {
        for (const [key] of Object.entries(userListener.property)) {
          var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          if (key >= firstDay && key < lastDay) {
            this.addedPropertyInMonth = this.addedPropertyInMonth + 1;
          }
        }
        await firebase
          .database()
          .ref("setting/property")
          .on("value", (snapshot) => {
            this.propertyMaxCount = snapshot.val();
            this.successMsgTitle = this.i18n(
              "drugDeal.facilities.successMsgTitle"
            );
            if (!this.isRTL) {
              this.successMsgDesc = `(This month posts: ${this.addedPropertyInMonth}, Maximum posts: ${this.propertyMaxCount})`;
            } else {
              this.successMsgDesc = `(هذا الشهر: ${this.addedPropertyInMonth}، الحد الأقصى شهرياً: ${this.propertyMaxCount})`;
            }
            this.publishDialog = true;
            setTimeout(() => {
              const lang = localStorage.getItem("language") || "en";
              this.$router.push({
                name: "facilities",
                params: {
                  lang,
                },
                query: {
                  page: 1,
                },
              });
            }, 3000);

            //#region
            // if(this.addedJobInMonth == this.jobMaxCount || this.addedJobInMonth > this.jobMaxCount){
            //     this.canNotAdd = true
            // }
            // else{
            //     this.canNotAdd = false
            // }
            // debugger
            // this.setLoadingAddMoreJobs(false)
            //#endregion
          });
      }
      // else{
      //     this.setLoadingAddMoreJobs(false)
      // }
    },
    async doSubmitFacility() {
      if (!this.$route.params.id) {
        this.recordId = this.generateId();
      }
      this.payload = {
        id: this.recordId,
        name: this.name,
        spaceArea: parseFloat(this.spaceArea),
        typeId: this.typeModel.id,
        cityId: this.cityModel.id,
        regionId: this.regionModel ? this.regionModel.id : null,
        listedForId: this.listedForModel.id,
        isClosed: this.statusName == "Closed" ? true : false,
        price: parseFloat(this.expectedPrice),
        income: parseFloat(this.averageIncome),
        address: this.address,
        phoneNumber: this.phoneNumber,
        description: this.description,
        postStatus: "pending",
        deactivationReasonId:
          Object.keys(this.deactivationModel).length > 0
            ? this.deactivationModel.id
            : null,
        isActive: this.active,
        images: {},
        // views:0,
        viewers: {},
        adNumber: this.recordId,
        countryCode: this.countryCode,
        countryName: this.countryName,
      };
      // =========== edit ================
      if (this.$route.params.id) {
        this.payload.images = this.images;
        this.payload.createdBy = this.createdBy;
        this.payload.createdAt = new Date(this.createdAt.seconds * 1000);
        this.payload.viewers = this.viewers;
        this.payload.postStatus = "pending";
        if (this.deletedImages.length > 0) {
          this.deletedImages.forEach(async (imageName) => {
            delete this.payload.images[imageName];
            await this.deleteFromStorage(imageName);
          });
        }
        if (
          this.rawFileOne ||
          this.rawFileTwo ||
          this.rawFileThree ||
          this.rawFileFour ||
          this.rawFileFive
        ) {
          if (this.rawFileOne) this.rawFiles.push(this.rawFileOne);
          if (this.rawFileTwo) this.rawFiles.push(this.rawFileTwo);
          if (this.rawFileThree) this.rawFiles.push(this.rawFileThree);
          if (this.rawFileFour) this.rawFiles.push(this.rawFileFour);
          if (this.rawFileFive) this.rawFiles.push(this.rawFileFive);
          this.rawFiles.forEach(async (rawFile) => {
            await this.uploadFromRequest(rawFile);
          });
        } else {
          await this.doUpdate({
            id: this.$route.params.id,
            values: this.payload,
          });
          await this.addMoreFacilities(this.userListener);
          // await this.addMoreFacilities(this.userListener)
          // setTimeout(() => {
          //     this.$router.push('/facilities')
          // },3000)
          // this.publishDialog = true
        }
      }
      // =============== create ============
      else {
        if (
          this.rawFileOne ||
          this.rawFileTwo ||
          this.rawFileThree ||
          this.rawFileFour ||
          this.rawFileFive
        ) {
          if (this.rawFileOne) this.rawFiles.push(this.rawFileOne);
          if (this.rawFileTwo) this.rawFiles.push(this.rawFileTwo);
          if (this.rawFileThree) this.rawFiles.push(this.rawFileThree);
          if (this.rawFileFour) this.rawFiles.push(this.rawFileFour);
          if (this.rawFileFive) this.rawFiles.push(this.rawFileFive);
          this.rawFiles.forEach(async (rawFile) => {
            await this.uploadFromRequest(rawFile);
          });
        } else {
          console.log(this.payload.images, "payloaddddd");
          await this.doCreate(this.payload);

          let today = Date.now();
          let user = await firebase
            .firestore()
            .collection("user")
            .doc(this.currentUser.id)
            .get();
          let userProperty = user.data().property;
          if (userProperty) {
            userProperty[today] = this.recordId;
            await firebase
              .firestore()
              .collection("user")
              .doc(this.currentUser.id)
              .update({
                property: userProperty,
              });
          } else {
            let userPropertyNew = {};
            userPropertyNew[today] = this.recordId;
            await firebase
              .firestore()
              .collection("user")
              .doc(this.currentUser.id)
              .update({
                property: userPropertyNew,
              });
          }
          await this.addMoreFacilities(this.userListener);
        }
      }
      // await this.addMoreFacilities(this.userListener)
    },
    closeSuccessDialog() {
      this.publishDialog = false;
    },
    async deleteFromStorage(imageName) {
      this.loadingUpdate = true;
      var storage = firebase.storage().ref();
      // delete old image
      var storageRef = storage.child(
        `property/${this.currentUser.id}/${this.recordId}/${imageName}`
      );
      // Delete the file
      storageRef.delete();
      // .then(() => {
      // console.log("deleted success");
      // })
      // .catch((error) => {
      // console.log("deleted error", error);
      // });
      this.loadingUpdate = false;
    },
    async uploadFromRequest(rawFile) {
      this.uploadImagesLoading = true;
      var storage = firebase.storage().ref();
      // var today = Date.now();
      var uploadTask = storage
        .child(
          `property/${this.currentUser.id}/${this.recordId}/${rawFile.name}`
        )
        .put(rawFile.value);

      await uploadTask.on(
        "state_changed",
        async (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (progress == 100) {
            this.storedImages = this.storedImages + 1;
          }
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        // (error) =>{
        //     console.log('error',error);
        // },
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            this.downloadURL = downloadURL;
            this.payload.images[rawFile.name] = this.downloadURL;
            if (this.storedImages == this.rawFiles.length) {
              this.uploadImagesLoading = false;
              if (this.$route.params.id) {
                await this.doUpdate({
                  id: this.recordId,
                  values: this.payload,
                });
              } else {
                await this.doCreate(this.payload);

                let today = Date.now();
                let user = await firebase
                  .firestore()
                  .collection("user")
                  .doc(this.currentUser.id)
                  .get();
                let userProperty = user.data().property;
                if (userProperty) {
                  userProperty[today] = this.recordId;
                  await firebase
                    .firestore()
                    .collection("user")
                    .doc(this.currentUser.id)
                    .update({
                      property: userProperty,
                    });
                } else {
                  let userPropertyNew = {};
                  userPropertyNew[today] = this.recordId;
                  await firebase
                    .firestore()
                    .collection("user")
                    .doc(this.currentUser.id)
                    .update({
                      property: userPropertyNew,
                    });
                }
              }
              await this.addMoreFacilities(this.userListener);
              // this.publishDialog = true
              // setTimeout(() => {
              //     this.$router.push('/facilities')
              // },3000)
            }
          });
        }
      );
    },

    //#region [uploadImage]
    uploadImageOne(value) {
      if (this.deleteImageOneFromStorage) {
        this.deletedImages.push(this.imageOneName);
        this.deleteImageOneFromStorage = false;
      }
      var today = Date.now();
      this.imageOneName = today;
      this.rawFileOne = {
        value,
        name: this.imageOneName,
      };
    },
    uploadImageTwo(value) {
      if (this.deleteImageTwoFromStorage) {
        this.deletedImages.push(this.imageTwoName);
        this.deleteImageTwoFromStorage = false;
      }
      var today = Date.now();
      this.imageTwoName = today;
      this.rawFileTwo = {
        value,
        name: this.imageTwoName,
      };
    },
    uploadImageThree(value) {
      if (this.deleteImageThreeFromStorage) {
        this.deletedImages.push(this.imageThreeName);
        this.deleteImageThreeFromStorage = false;
      }
      var today = Date.now();
      this.imageThreeName = today;
      this.rawFileThree = {
        value,
        name: this.imageThreeName,
      };
    },
    uploadImageFour(value) {
      if (this.deleteImageFourFromStorage) {
        this.deletedImages.push(this.imageFourName);
        this.deleteImageFourFromStorage = false;
      }
      var today = Date.now();
      this.imageFourName = today;
      this.rawFileFour = {
        value,
        name: this.imageFourName,
      };
    },
    uploadImageFive(value) {
      if (this.deleteImageFiveFromStorage) {
        this.deletedImages.push(this.imageFiveName);
        this.deleteImageFiveFromStorage = false;
      }
      var today = Date.now();
      this.imageFiveName = today;
      this.rawFileFive = {
        value,
        name: this.imageFiveName,
      };
    },
    //#endregion
    //#region [Delete Image]
    deleteImageOne(value) {
      this.imageOneName = null;
      this.imageOneUrl = null;
      if (this.deleteImageOneFromStorage) {
        this.deletedImages.push(value);
        this.deleteImageOneFromStorage = false;
      }
    },
    deleteImageTwo(value) {
      this.imageTwoName = null;
      this.imageTwoUrl = null;
      if (this.deleteImageTwoFromStorage) {
        this.deletedImages.push(value);
        this.deleteImageTwoFromStorage = false;
      }
    },
    deleteImageThree(value) {
      this.imageThreeName = null;
      this.imageThreeUrl = null;
      if (this.deleteImageThreeFromStorage) {
        this.deletedImages.push(value);
        this.deleteImageThreeFromStorage = false;
      }
    },
    deleteImageFour(value) {
      this.imageFourName = null;
      this.imageFourUrl = null;
      if (this.deleteImageFourFromStorage) {
        this.deletedImages.push(value);
        this.deleteImageFourFromStorage = false;
      }
    },
    deleteImageFive(value) {
      this.imageFiveName = null;
      this.imageFiveUrl = null;
      if (this.deleteImageFiveFromStorage) {
        this.deletedImages.push(value);
        this.deleteImageFiveFromStorage = false;
      }
    },
    //#endregion
  },
  components: {
    // VueAvatar: VueAvatar,
    [AddDialog.name]: AddDialog,
    [SuccessDialog.name]: SuccessDialog,
    VuePhoneNumberInput,
  },
  mounted() {
    this.$q.iconSet.field.error = "img:/images/invalid-email.png";
    this.setNavbar("services");
    this.setFooter(false);
    setTimeout(() => {}, 1000);
    this.setHeaderBg("bg-shadow");
  },
  async created() {
    document.title =
      this.i18n("app.title") +
      " | " +
      this.i18n("drugDeal.services.facilities");
    if (!this.mountedFacilityTypeTable) {
      await this.doFetchFacilityType();
      this.setMountedFacilityTypeTable(true);
    }
    if (!this.mountedCityTable) {
      await this.doFetchCity();
      this.setMountedCityTable(true);
    }
    if (!this.mountedFacilityListedfForTable) {
      await this.doFetchFacilityListedFor();
      this.setMountedFacilityListedForTable(true);
    }
    if (!this.mountedDeactivationReasonTable) {
      await this.doFetchDeactivationReason();
      this.setMountedDeactivationReasonTable(true);
    }

    if (this.$route.params.id && this.$route.params.edit == "edit") {
      // if(!this.recordMounted){
      this.edit = true;
      this.recordId = this.$route.params.id;
      await this.doFind({ id: this.$route.params.id });
      //     this.setRecordMounted(true)
      // }
    }
  },
};
